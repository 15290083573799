import React, { CSSProperties, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Map, ObjectManager, withYMaps } from 'react-yandex-maps';
import { api } from 'src/api/api';
import appealPin from 'src/assets/img/new-landing/appealPin.svg';
import appealPinSelected from 'src/assets/img/new-landing/appealPinSelected.svg';
import projectPin from 'src/assets/img/new-landing/projectPin.svg';
import projectPinSelected from 'src/assets/img/new-landing/projectPinSelected.svg';
import { exceptionsRegions, exceptionsRegionsIso3166 } from 'src/constants/exceptions-regions';
import {
  APPEAL_TEXT_VARIANTS,
  clusterNumbers,
  clusterOptions,
  DEFAULT_MAP_STATE,
  FETCH_REGIONS_CONFIG,
  GEO_LOCATION_CONTROL_OPTIONS,
  iconLayout,
  MAIN_MAP,
  mapOptions,
  mapStyles,
  PROJECT_TEXT_VARIANTS,
  ZOOM_CONTROL_OPTIONS,
} from 'src/constants/map';
import { useAppContext } from 'src/store/AppContenxt/AppContenxt';
import {
  EEntityType,
  EGetType,
  ExtendType,
  IProject,
  IShortAppeal,
  TBaseItemType,
  TCoords,
  TEntityItems,
  TEntityPopup,
  TEntityRef,
  TProjectObject,
} from 'src/types/LandingMapTypes';
import {
  createFeatureTypes,
  debounce,
  fetchData,
  getPinOptions,
  getPinType,
  prepareAppealData,
  preparedPopupData,
  prepareProjectData,
  setClusterIcons,
  setClusterPinOptions,
  setPinObj,
  setPinOptions,
  setYmapsRegionName,
} from 'src/utils/utils';
import LandingMapPopup from '../LandingMapPopup';
import { AppealBodyContent, AppealHeaderContent } from '../LandingMapPopup/AppealContent';
import { ProjectBodyContent, ProjectHeaderContent } from '../LandingMapPopup/ProjectContent';
import { createLayerControl } from '../LayerControl/LayerControl';
import '../LayerControl/LayerControl.css';
import { ELayerType } from '../LayerControl/LayerControlEnum';
import styles from './LandingMap.module.css';

const LandingMap: FC<any> = React.memo((props) => {
  const { ymaps } = props;
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get('projectId');
  const {
    store: { isLoadingLocation, isLoadingRegions, currentRegion, geolocationBounds },
  } = useAppContext();
  const mapRef: any = useRef();
  const appealObjectManagerRef: any = useRef();
  const projectObjectManagerRef: any = useRef();
  const objectManagersMap = {
    [EEntityType.appeals]: appealObjectManagerRef,
    [EEntityType.projects]: projectObjectManagerRef,
  };
  const prevObjectsWithMetaData = useRef<TEntityRef>({
    isCluster: false,
    objects: undefined,
    objectId: 0,
    type: EEntityType.appeals,
  });
  const prevBoundsForEqualRef = useRef<TCoords>([]);
  const lastBoundsRef = useRef<TCoords>([]);
  const lastZoomRef = useRef<number>(0);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [layerState, setLayerState] = useState<ELayerType>(ELayerType.all);
  const [mapState, setMapState] = useState(DEFAULT_MAP_STATE);
  const [appeals, setAppeals] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [isPopupOpen, setPopupOpen] = useState<TEntityPopup>({
    appeals: false,
    projects: false,
  });
  const [entityItems, setEntityItems] = useState<TEntityItems>({
    appeals: [],
    projects: [],
  });
  const [objects, setObjects] = useState<TProjectObject[]>([]);
  const [isShowMap, setShowMap] = useState(false);
  const hasObjects = Boolean(objects.length);

  const layerActions = {
    [ELayerType.all](coords: TCoords) {
      fetchData<any>(coords, EGetType.getAppealsMap, setAppeals);
      fetchData<any>(coords, EGetType.getProjectsMap, setProjects);
    },
    [ELayerType.appeals]: (coords: TCoords) => fetchData<any>(coords, EGetType.getAppealsMap, setAppeals),
    [ELayerType.projects]: (coords: TCoords) => fetchData<any>(coords, EGetType.getProjectsMap, setProjects),
  };

  const debouncedLayerAction = debounce(layerActions[layerState], 500);

  const fetchByIds =
    (type: EEntityType) =>
    async (ids: number[]): Promise<void> => {
      const method = ('get' + (type.charAt(0).toUpperCase() + type.slice(1)) + 'ByRepeatIds') as
        | 'getAppealsByRepeatIds'
        | 'getProjectsByRepeatIds';

      try {
        setIsLoadingDetails(true);
        const data = await api[method]({ ids });
        if (Array.isArray(data)) {
          setEntityItems((state) => ({
            ...state,
            [type]: data.map(type === EEntityType.appeals ? prepareAppealData : prepareProjectData),
          }));
          setPopupOpen((state) => ({ ...state, [type]: true }));
        }
      } catch (e) {
      } finally {
        setIsLoadingDetails(false);
      }
    };

  const getYmapsRegions = async (ymaps: any) => {
    try {
      const [{ features: featuresRU }, { features: featuresUA }] = await Promise.all(
        FETCH_REGIONS_CONFIG.map(({ location, ...restOptions }) => ymaps.borders.load(location, restOptions)),
      );
      const lostRegions = featuresUA
        .filter((region: any) => exceptionsRegionsIso3166.includes(region.properties.iso3166))
        .map((region: { properties: { iso3166: (typeof exceptionsRegionsIso3166)[number] } }) =>
          setYmapsRegionName(region, exceptionsRegions[region.properties.iso3166]),
        );

      return [...featuresRU, ...lostRegions];
    } catch (e) {
      throw e;
    }
  };

  const clearPrevSelectedObjectsOnMap = (type: EEntityType) => {
    const { isCluster } = prevObjectsWithMetaData.current;
    const currentObjectManager = objectManagersMap[prevObjectsWithMetaData.current.type].current;
    const objects = currentObjectManager
      ? isCluster
        ? currentObjectManager.clusters
        : currentObjectManager.objects
      : prevObjectsWithMetaData.current.objects;
    const { objectId } = prevObjectsWithMetaData.current;
    if (objects && objectId) {
      const setOptions = 'set' + (isCluster ? 'Cluster' : 'Object') + 'Options';
      const setPinOpts = ('set' + (isCluster ? 'Cluster' : '') + 'PinOptions') as keyof typeof setPinObj;
      const pinOptions = getPinOptions(prevObjectsWithMetaData.current.type);
      const pinType = getPinType(prevObjectsWithMetaData.current.type, appealPin, projectPin);
      objects[setOptions](objectId, setPinObj[setPinOpts](pinType, pinOptions));
      prevObjectsWithMetaData.current = {
        isCluster,
        type,
        objects: undefined,
        objectId: 0,
      };
    }
  };

  const onClusterClickHandler = useCallback(
    (type: EEntityType, fetchDetails: (ids: number[]) => Promise<void>) => (e: any) => {
      const objectId = e.get('objectId');
      const target = e.get('target');
      const currentTarget = e.get('currentTarget');
      const currentZoom = currentTarget.getMap().getZoom();
      const currentType = target.getById(objectId).getData().type;
      const isCluster = currentType.toLowerCase() === 'cluster';
      const isAppeals = type === EEntityType.appeals;
      const isEqualObjectId = prevObjectsWithMetaData.current.objectId == objectId;
      const isEqualType = type == prevObjectsWithMetaData.current.type;
      const pinOptions = getPinOptions(type);
      const pinType = getPinType(type, appealPinSelected, projectPinSelected);

      if (!isEqualType) {
        clearPrevDataAndClosePopup(prevObjectsWithMetaData.current.type);
      }

      clearPrevSelectedObjectsOnMap(prevObjectsWithMetaData.current.type);

      if (isCluster) {
        if (currentZoom === mapOptions.maxZoom) {
          const { clusters } = currentTarget;
          clusters.setClusterOptions(objectId, setClusterPinOptions(pinType, pinOptions));
          prevObjectsWithMetaData.current = {
            isCluster,
            type,
            objects: clusters,
            objectId,
          };
          if (!isEqualObjectId)
            fetchDetails(
              clusters
                .getById(objectId)
                .features.map((feature: ExtendType<TBaseItemType>) =>
                  isAppeals ? feature.id : feature.options.source.projectId,
                ),
            );
        } else {
          clearPrevDataAndClosePopup(prevObjectsWithMetaData.current.type);
        }
      } else {
        const { objects } = currentTarget;
        objects.setObjectOptions(objectId, setPinOptions(pinType, pinOptions));
        prevObjectsWithMetaData.current = {
          isCluster,
          type,
          objects,
          objectId,
        };
        const object = objects.getById(objectId) as ExtendType<TBaseItemType>;
        if (!isEqualObjectId) fetchDetails([isAppeals ? object.id : object.options.source.projectId]);
      }
    },
    [],
  );

  const clearPrevDataAndClosePopup = (type: EEntityType) => {
    setPopupOpen((state) => ({ ...state, [type]: false }));
    setEntityItems((state) => ({ ...state, [type]: [] }));
  };

  const clearRefAndClosePopup = useCallback(
    (type: EEntityType) => () => {
      clearPrevSelectedObjectsOnMap(type);
      clearPrevDataAndClosePopup(type);
      setObjects([]);
    },
    [],
  );

  const onBoundsChange = useCallback(
    (e: any) => {
      const newBounds = e.get('newBounds');
      const newZoom = e.get('newZoom');
      if (!mapState.bounds || hasObjects) return;
      lastBoundsRef.current = newBounds;
      if (prevBoundsForEqualRef.current?.length) {
        const isContainsNewBounds = ymaps?.util?.bounds.containsBounds(prevBoundsForEqualRef.current, newBounds);
        if (!isContainsNewBounds || newZoom < lastZoomRef.current) {
          debouncedLayerAction(newBounds);
          prevBoundsForEqualRef.current = newBounds;
          lastZoomRef.current = newZoom;
        }
      }
    },
    [layerState, hasObjects, mapState.bounds],
  );

  const appealFeatures = [ELayerType.all, ELayerType.appeals].includes(layerState) && !hasObjects ? appeals : [];
  const projectFeatures = [ELayerType.all, ELayerType.projects].includes(layerState) && !hasObjects ? projects : [];
  const objectFeatures = createFeatureTypes<any>(objects);

  useEffect(() => {
    if (geolocationBounds.length) {
      getYmapsRegions(ymaps)
        .then((YRegions) => {
          const regionShow = YRegions.find((YRegion) => YRegion?.properties?.name === currentRegion.name);
          if (regionShow) {
            setMapState({
              bounds: geolocationBounds,
            });
          }
        })
        .catch(console.log)
        .finally(() => setShowMap(true));
    }
  }, [currentRegion.name, geolocationBounds]);

  useEffect(() => {
    if (mapRef.current && isShowMap) {
      const bounds = mapRef.current.getBounds();
      if (!prevBoundsForEqualRef.current?.length) {
        debouncedLayerAction(bounds);
        prevBoundsForEqualRef.current = bounds;
      }
    }
  }, [isShowMap]);

  useEffect(() => {
    if (mapRef.current && ymaps && lastBoundsRef.current?.length) {
      const containerSize = mapRef.current.container.getSize();
      const projection = mapRef.current.options.get('projection');
      const objectsCoords = objects.map(({ lat, lon }) => [lat, lon]);
      const objectsBounds = ymaps?.util?.bounds.fromPoints(objectsCoords);
      const { center, zoom } = ymaps?.util?.bounds.getCenterAndZoom(objectsBounds, containerSize, projection, {
        margin: [20, 0, 20, 0],
      });
      if (Array.isArray(center) && !isNaN(zoom)) {
        if (hasObjects) {
          setMapState({ center, zoom });
        } else if (!mapState.bounds && prevBoundsForEqualRef.current.length) {
          setMapState({ bounds: lastBoundsRef.current });
        }
      }
    }
  }, [hasObjects]);

  useEffect(() => {
    if (mapRef.current && isShowMap) {
      [
        new ymaps.control.ZoomControl(ZOOM_CONTROL_OPTIONS),
        new ymaps.control.GeolocationControl(GEO_LOCATION_CONTROL_OPTIONS),
        createLayerControl(ymaps, layerState, setLayerState, () =>
          clearRefAndClosePopup(prevObjectsWithMetaData.current.type)(),
        ),
      ].forEach((control) => mapRef.current.controls.add(control));
    }
  }, [isShowMap]);

  useEffect(() => {
    const map = document.getElementById(MAIN_MAP);
    if (projectId && isShowMap && ymaps && mapRef.current && map) {
      map.scrollIntoView({ behavior: 'smooth' });
      fetchByIds(EEntityType.projects)([Number(projectId)]);
    }
  }, [projectId, isShowMap, ymaps, mapRef.current]);

  return (
    <div>
      {(isShowMap || !isLoadingLocation || !isLoadingRegions) && (
        <div className={styles.mapWrapper}>
          <div id={MAIN_MAP} className={styles.headline}>
            <h3>Узнайте, о чём спрашивают в вашем регионе</h3>
            <p>
              На карте вы можете посмотреть все публичные сообщения и обращения региона — узнать, что волнует ваших
              соседей или жителей других регионов
            </p>
          </div>
          <Map
            state={mapState}
            instanceRef={mapRef}
            style={mapStyles as CSSProperties}
            options={mapOptions}
            onBoundschange={onBoundsChange}
            className={styles.mapBlock}
          >
            {Boolean(appealFeatures.length) && (
              <ObjectManager
                instanceRef={appealObjectManagerRef}
                features={appeals}
                clusters={{
                  clusterIcons: setClusterIcons(appealPin, [0, -30]),
                  clusterNumbers,
                }}
                objects={{
                  iconLayout,
                  ...setPinOptions(appealPin, [0, -30]),
                }}
                options={clusterOptions}
                onClick={onClusterClickHandler(EEntityType.appeals, fetchByIds(EEntityType.appeals))}
              />
            )}

            {Boolean(projectFeatures.length) && (
              <ObjectManager
                instanceRef={projectObjectManagerRef}
                features={projects}
                clusters={{
                  clusterIcons: setClusterIcons(projectPin),
                  clusterNumbers,
                }}
                objects={{
                  iconLayout,
                  ...setPinOptions(projectPin),
                }}
                options={clusterOptions}
                onClick={onClusterClickHandler(EEntityType.projects, fetchByIds(EEntityType.projects))}
              />
            )}

            {Boolean(objectFeatures.length) && (
              <ObjectManager
                features={objectFeatures}
                clusters={{
                  clusterIcons: setClusterIcons(projectPin),
                  clusterNumbers,
                }}
                objects={{
                  iconLayout,
                  ...setPinOptions(projectPin),
                }}
                options={clusterOptions}
              />
            )}

            {isPopupOpen.appeals && (
              <LandingMapPopup<IShortAppeal>
                items={preparedPopupData<IShortAppeal>(entityItems.appeals)}
                onClosePopup={clearRefAndClosePopup(EEntityType.appeals)}
                textVariants={APPEAL_TEXT_VARIANTS}
                isLoading={isLoadingDetails}
              >
                <AppealHeaderContent key="top" />
                <AppealBodyContent key="bottom" />
              </LandingMapPopup>
            )}
            {isPopupOpen.projects && (
              <LandingMapPopup<IProject>
                items={preparedPopupData<IProject>(entityItems.projects)}
                onClosePopup={clearRefAndClosePopup(EEntityType.projects)}
                textVariants={PROJECT_TEXT_VARIANTS}
                isLoading={isLoadingDetails}
              >
                <ProjectHeaderContent key="top" setObjects={setObjects} />
                <ProjectBodyContent key="bottom" />
              </LandingMapPopup>
            )}
          </Map>
        </div>
      )}
    </div>
  );
});

export const LandingMapWithYMaps = memo(
  withYMaps(LandingMap, true, [
    'control.ListBox',
    'control.ListBoxItem',
    'control.ZoomControl',
    'control.GeolocationControl',
    'templateLayoutFactory',
    'suggest',
    'geocode',
    'borders',
    'Placemark',
    'geoQuery',
    'util.bounds',
  ]),
);
