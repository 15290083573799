import React, { Children, cloneElement, isValidElement, PropsWithChildren } from 'react';
import { ICommonItems, TAccordionShort } from '../../../types/LandingMapTypes';
import { IconDropdown } from '../../icons/IconDropdown';
import { IconDropdownUp } from '../../icons/IconDropdownUp';
import styles from './UIAccordion.module.css';

interface IAccordionProps<T> {
  item: TAccordionShort<T>;
  onToggle?: (id: number) => void;
  onToggleActive?: (id: number) => void;
  noneShown: boolean;
}

export function AccordionItem<T extends ICommonItems>({
  onToggle,
  onToggleActive,
  item,
  noneShown,
  children,
}: PropsWithChildren<IAccordionProps<T>>) {
  return (
    <div className={styles.accordion}>
      {Children.map(children, (Child, index) =>
        !isValidElement(Child) ? null : Child.key == 'top' ? (
          <div className={styles.accordionHeader} key={index}>
            <div className={styles.accordionTitle}>{cloneElement(Child, { ...item, onToggleActive, noneShown })}</div>
            <div className={styles.accordionCloseIcon} onClick={() => onToggle?.(item.ndxId)}>
              {item.isOpen ? <IconDropdownUp /> : <IconDropdown />}
            </div>
          </div>
        ) : !Child.key || Child.key == 'bottom' ? (
          <>
            {item.isOpen && (
              <div key={index} className={styles.accordionDropdown}>
                {cloneElement(Child, { ...item })}
              </div>
            )}
          </>
        ) : null,
      )}
    </div>
  );
}
