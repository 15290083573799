import React, { FC } from 'react';
import { IProject } from 'src/types/LandingMapTypes';
import styles from './ProgressWithDate.module.css';

const ProgressWithDate: FC<Partial<IProject>> = ({ decideUntilDate, progress }) => {
  const date = decideUntilDate ? new Date(decideUntilDate).toLocaleDateString('ru-RU') : '';
  const progressValue = progress ?? 0;
  return (
    <div className={styles.progress}>
      <div className={styles.progressDate}>{date}</div>
      <div className={styles.progressBarWrap}>
        <div className={styles.progressBar}>
          <div className={styles.progressBarIndicator} style={{ width: progressValue + '%' }}></div>
        </div>
        {progressValue + '%'}
      </div>
    </div>
  );
};
export default ProgressWithDate;
